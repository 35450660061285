<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运费管理</el-breadcrumb-item>
      <el-breadcrumb-item>顺丰运费</el-breadcrumb-item>
    </el-breadcrumb>
    
    <el-row  class="justifypo">
    <el-col class="justify"  :span="14" >
      <el-row>
        <el-col :span="3.3" class="mr5" ><div class="grid-content bg-purple-dark">
            <el-button  class="el-buttonr" size="mini" type="success" @click="outerVisible = true">新增</el-button>
          </div>
         </el-col>
        <el-col :span="12">
        <div class="grid-content bg-purple-dark">
            <el-button class="el-buttonr" size="mini" type="primary" @click="Visible = true">编辑</el-button>
          </div>
        </el-col>
      </el-row>
      <div class="icon" >
        <el-table :data="gridData">
          <el-table-column  show-overflow-tooltip label="序号" min-width="0" fixed="left">
            <template slot-scope="scope">
             <i class="el-icon-remove"></i>
              <p>{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column  property="date" label="顺丰商品分类" show-overflow-tooltip></el-table-column>
          <el-table-column property="name" label="富航商品分类" width="150"  show-overflow-tooltip ></el-table-column>
          <el-table-column property="address"   label="一公里起步价" width="150" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
      </el-col>
      <el-col class="justify" :span="9" >
      <div style=" float: left; margin: 50px 0 0 00px; width: 400px">
        <el-row class="mdro6">
          <el-input style="width: 100px; background-color: " size="mini"   />
          <span>公里</span>
          <span style="padding-left: 20px">加￥</span>
          <el-input style="width: 50px; background-color: " size="mini"    />
          <span>/公里</span>
        </el-row>
           <el-row class="mdro6">
          <el-input style="width: 100px; background-color: " size="mini"   />
          <span>公里</span>
          <span style="padding-left: 20px">加￥</span>
          <el-input style="width: 50px; background-color: " size="mini"    />
          <span>/公里</span>
        </el-row>
           <el-row class="mdro6">
          <el-input style="width: 100px; background-color: " size="mini"   />
          <span>公里</span>
          <span style="padding-left: 20px">加￥</span>
          <el-input style="width: 50px; background-color: " size="mini"    />
          <span>/公里</span>
        </el-row>
        <el-row>
          <span>超过</span>
          <el-input style="width: 100px; " size="mini"   />
          <span>公里</span>
          <span style="padding-left: 20px"> 不提供配送服务</span>
        </el-row>
      </div>
        </el-col>
    </el-row>
    <!-- 新增顺丰 -->
    <el-dialog
      width="30%"
      title="物流分类"
      :append-to-body="true"
      :visible.sync="outerVisible"
    >
      <el-form :model="form">
        <el-form-item label="顺丰商品分类" required>
          <el-col class="mr15" :span="12">
            <el-input size="mini"   ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="富航商品分类" required>
          <el-col class="mr15" :span="12">
            <el-input size="mini"   ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="一公里起步价" required>
          <el-col class="mr15" :span="12">
            <el-input size="mini"   ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="innerVisible = false">取消</el-button>
        <el-button type="primary" @click="innerVisible = false">确定</el-button>
      </span>
    </el-dialog>
    
    <!-- 编辑顺丰配送服务 -->
    <el-dialog
      width="30%"
      title="配送服务"
      :append-to-body="true"
      :visible.sync="Visible"
    >
      <el-row class="mdro7">
        <el-input  style="width: 100px; " size="mini"   />
        <span>公里</span>
        <span style="padding-left: 20px">加￥</span>
        <el-input style="width: 50px; " size="mini"   />
        <span>/公里</span>
      </el-row>
       <el-row class="mdro7">
        <el-input  style="width: 100px; " size="mini"   />
        <span>公里</span>
        <span style="padding-left: 20px">加￥</span>
        <el-input style="width: 50px; " size="mini"   />
        <span>/公里</span>
      </el-row>
      <el-row class="mdro7">
        <el-input  style="width: 100px; " size="mini"   />
        <span>公里</span>
        <span style="padding-left: 20px">加￥</span>
        <el-input style="width: 50px; " size="mini"   />
        <span>/公里</span>
      </el-row>
      <el-row class="mdro7">
        <el-input  style="width: 100px; " size="mini"   />
        <span>公里</span>
        <span style="padding-left: 20px">加￥</span>
        <el-input style="width: 50px; " size="mini"   />
        <span>/公里</span>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="innerVisible = false">取消</el-button>
        <el-button type="primary" @click="innerVisible = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Visible: false,
      outerVisible: false,
      dialogImageUrl: "",
      dialogVisible: false,
      gridData: [
        {
          date: "2116-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2216-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
           {
          date: "2216-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
           {
          date: "2216-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2316-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2416-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
    };
  },

  methods: {
    handleRemove(file, fileList) {
  
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    beforeUp(file) {
      var reader = new FileReader();
      reader.onload = function (event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function () {
          
        };
      };
      reader.readAsDataURL(file);
    },

    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style lang="scss" scoped>
.justifypo{
  display: flex;
justify-content: space-between;

.justify{
 height: 300px;
background: #fff;
padding: 16px;
border-radius:10px ;

}
.icon{
  height: 250px;
  overflow: auto;
  i{
    float: left;
    margin-top:5px ;
    padding-right:2px ;
    color: red;
  }
}
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mdro7 {
  display: flex;
  justify-content: center;

  margin: 0 0 15px 0;
}
.mdro6 {
  margin: 0 0 15px 32px;
}
.mr5 {
  margin-right: 15px;
}
</style>